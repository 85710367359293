.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  font-family: 'Courier New', monospace;
  min-width: 320px;
}

.project-tile {
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc; /* Optional: border for the project tile */
}

.project-tile img {
  width: 100%;
  display: block;
}

.aspect-ratio-container {
  position: relative;
  width: 100%;
  padding-bottom: 66.6667%; /* 3:2 Aspect ratio */
  overflow: hidden;
}

.aspect-ratio-container img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.project-tile:hover .card-body {
  background: linear-gradient(to right, #3a9d9d, #277ab1);
  color: white;
  transition: background-color 0.2s ease-in-out; /* Transition for hover effect */
}

.card-title {
  font-weight: 600;
  text-wrap: balance;
}

.project-tile {
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc; /* Optional: border for the project tile */
}

.project-tile img {
  width: 100%;
  display: block;
  transition: filter 0.3s ease-in-out; /* Transition for hover effect */
}

.project-tile:hover img {
  filter: brightness(40%); /* Hover effect: Brighten and blur image */
}

.project-title {
  position: absolute;
  top: 50%;
  left: 50%;
  width: inherit;
  transform: translate(-50%, -50%);
  text-align: center; /* Center text horizontally */
  color: rgba(255, 255, 255, 0.9); /* Default color: Light with some transparency */
  font-weight: bold;
  font-size: 1.8em; /* Adjust the font size as needed */
  opacity: 0;
  transition: opacity 0.2s ease-in-out; /* Transition for hover effect */
}

div.project-featured {
  position: absolute;
  top: 15%;
  left: 90%;
  transform: translate(-50%, -50%);
  color: gold;
  font-weight: bold;
  font-size: 2.5rem;
}

span.project-featured {
  color: gold;
  margin-right: 10px;
}

.project-tile:hover .project-title {
  opacity: 1; /* Display title on hover */
}

a.card.project-tile, a.card.project-tile:hover {
  text-decoration: none; /* Remove underline from links */
}

.published-date-container {
  text-align: right;
}

.published-label {
  margin-right: 10px; /* Adjust spacing between label and date */
}

.project-content img {
  max-width: 100%;
  max-height: 50vh;
  display: block;
  margin: 3% auto;
  text-align: center;
}

.contact-links > span {
  display: block;
}

.hero-banner {
  padding: 50px 0; /* Adjust padding as needed */
}

.hero-banner h1 {
  font-size: 2.5em; /* Adjust font size */
  margin-bottom: 20px; /* Adjust margin as needed */
}

.hero-banner p {
  font-size: 1.2em; /* Adjust font size */
}

.inline-block {
  display: inline-block;
}

.navbar {
  background-color: #04162D;
}

.navbar .navbar-brand, .navbar .navbar-nav .nav-link {
  color: #fff;
}

.navbar .navbar-brand b {
  color: #3B9C9D;
}

.footer {
  background: linear-gradient(to right, #3a9d9d, #277ab1);
  color: white;
}

.mb-10 {
  margin-bottom: 10px;
}

.tag {
  margin-left: 2px;
  margin-right: 2px;
  background-color: #3B9C9D!important;
}

.bold {
  font-weight: bold;
}